<template>
	<ul class="material-video" v-if="slides && slides.length > 0">
		<li
			class="material-video__item"
			v-for="(slide, videoIndex) in slides"
			:key="videoIndex"
		>
			<swiperSlide class="main-slider__slide">
				<div class="main-slider__video" @click="index = videoIndex">
					<img
						:src="slide.thumbnail"
						:alt="slide.title"
						class="main-slider__video-bg"
					/>
					<p class="main-slider__video-text">
						{{ slide.title }}
					</p>
				</div>
				<h4>{{ slide.title }}</h4>
			</swiperSlide>
		</li>
				<CoolLightBox :items="slides" :index="index" @close="index = null">
		</CoolLightBox>
	</ul>
</template>

<script>
import {
	//swiper,
	swiperSlide,
} from 'vue-awesome-swiper'
import CoolLightBox from 'vue-cool-lightbox'

import 'swiper/css/swiper.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
	name: 'NewsSlider',

	components: {
		//swiper,
		swiperSlide,
		CoolLightBox,
	},

	data() {
		return {
			index: null,
			swiperOption: {
				loop: false,
				pagination: {
					el: '.swiper-pagination--video',
				},
				navigation: {
					nextEl: '.swiper-button-next--video',
					prevEl: '.swiper-button-prev--video',
				},
				slidesPerView: 2,
				spaceBetween: 30,
				breakpoints: {
					870: {
						slidesPerView: 1,
					},
				},
			},
		}
	},

	computed: {
		swiper() {
			const videos = this.$store.getters.VIDEOS

			if (videos && videos.length > 0) {
				this.swiper.slideTo(1, 0, false)
				return this.$refs.mySwiper.$swiper
			} else {
				return null
			}
		},

		slides() {
			const videos = this.$store.getters.VIDEOS

			if (videos) {
				videos.forEach((video) => {
					video.src = video.url
				})
			}

			return videos
		},
	},
	mounted() {
		this.$store.dispatch('GET_VIDEOS')
	},
}
</script>
